import Account from "components/account";
import Icon from "components/icon";
import Link from "next/link";
import { ChainId } from "server/services/ethereum/constants";
import { Nft } from "server/services/nft-data";
import { ImgHTMLAttributes } from "react";

interface NftCardProps {
    nft: Nft;
}

export default function NftCard({
    nft: { name, address, tokenId, imagePreviewLargeUrl, creator, type },
}: NftCardProps) {
    return (
        <Link
            href={`/nft/${ChainId.Mainnet}/${address}/${tokenId}`}
            className="rounded-lg shadow-xl overflow-hidden flex flex-col"
        >
            <div className="aspect-w-1 aspect-h-1 relative">
                {type && ["audio", "video"].includes(type) && (
                    <div className="absolute h-12 w-12 top-4 right-4 left-auto flex items-center justify-center z-10 bg-black/30 rounded-full shadow-xs">
                        <Icon name={type} className="h-6 w-6 text-white" />
                    </div>
                )}
                <NftImage
                    src={imagePreviewLargeUrl}
                    className="object-cover w-full h-full"
                />
            </div>
            <div className="flex flex-col gap-3 p-5">
                <div className="flex flex-row justify-between gap-3">
                    <div className="flex flex-row gap-1 truncate items-center">
                        <div className="text-md font-semibold truncate">
                            {name}
                        </div>
                    </div>
                    <Account account={creator} size="extrasmall" />
                </div>
            </div>
        </Link>
    );
}

interface NftImageProps
    extends Omit<ImgHTMLAttributes<HTMLImageElement>, "src"> {
    src: string | null;
}

function NftImage({ className, src, ...imgProps }: NftImageProps): JSX.Element {
    if (!src) {
        return (
            <div className={`justify-center items-center flex ${className}`}>
                <Icon name="image" className="h-12 w-12" />
            </div>
        );
    }

    // eslint-disable-next-line @next/next/no-img-element
    return <img src={src} className={className} alt="NFT" {...imgProps} />;
}
