import { EthereumAccount } from "types";
import { Hash } from "viem";

export function shorten(item: string): string {
    return `${item.slice(0, 6)}...${item.slice(item.length - 4)}`;
}

export function accountIdentifier(ethereumAccount: EthereumAccount): string {
    return ethereumAccount.ensName
        ? ethereumAccount.ensName
        : shorten(ethereumAccount.address);
}

export function shortenTransaction(hash: Hash): string {
    return shorten(hash);
}
