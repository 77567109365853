import InfiniteScrollTrigger from "components/InfiniteScrollTrigger";
import Loader from "components/loader";
import NftCard from "components/NftCard";
import { UserNftsResponse } from "pages/api/users/[address]/nfts/types";
import { SWRInfiniteResponse } from "swr/infinite";

interface NftGridProps {
    response: SWRInfiniteResponse<UserNftsResponse>;
}

export default function NftGrid({
    response: { data, size, setSize, isValidating },
}: NftGridProps): JSX.Element {
    if (!data) {
        return (
            <div className="flex justify-center mt-10">
                <Loader size="large" />
            </div>
        );
    }

    const nfts = data.map((page) => page.nfts).flat();

    if (!nfts.length) {
        return <div className="text-sm text-gray-500 mt-10">No NFTs yet!</div>;
    }

    const hasMore = data[data.length - 1].cursor;

    return (
        <>
            <div className="mt-10 grid gap-10 md:grid-cols-3">
                {nfts.map((nft, index) => (
                    <NftCard
                        nft={nft}
                        key={`${nft.address}-${nft.tokenId}-${index}`}
                    />
                ))}
                <InfiniteScrollTrigger
                    fetchNextPage={() => setSize(size + 1)}
                    stopFetching={!hasMore}
                    isValidating={isValidating}
                />
            </div>
            {hasMore && (
                <div className="mt-10 w-full flex justify-center">
                    <Loader size="medium" />
                </div>
            )}
        </>
    );
}
