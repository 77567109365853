import { useSIWE } from "connectkit";
import useIsSessionReady from "context/is-session-ready";
import { addressValidator } from "lib/zod/validators.mjs";
import { EnsReverseResolveResponse } from "pages/api/ens/reverse-resolve/[address].page";
import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";
import { EthereumAccount } from "types";
import { useAccount, useDisconnect } from "wagmi";

interface HookResult {
    isReady: boolean;
    user?: EthereumAccount | null;
    signOutAndDisconnect: () => unknown;
}

export default function useUser(): HookResult {
    const isSessionReady = useIsSessionReady();

    const { isSignedIn, isReady: isSiweReady, signOut } = useSIWE();
    const { address } = useAccount();
    const { disconnect } = useDisconnect();

    const { data } = useSWRImmutable<EnsReverseResolveResponse>(
        address && `/api/ens/reverse-resolve/${address}`
    );

    const signOutAndDisconnect = useCallback(async () => {
        return Promise.all([signOut(), disconnect()]);
    }, [signOut, disconnect]);

    const user: EthereumAccount | null =
        isSignedIn && address
            ? {
                  address: addressValidator.parse(address),
                  ensName: data?.ensName ?? null,
              }
            : null;

    const isReady = isSiweReady && isSessionReady;

    return {
        isReady,
        user,
        signOutAndDisconnect,
    };
}
