export default function HomageIcon(): JSX.Element {
    return (
        <svg
            width="24"
            height="20"
            viewBox="0 0 24 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.07689 0.615196C2.71443 0.953732 0.843902 2.75285 0.471787 5.11025C0.220107 6.70467 0 8.52168 0 10C0 11.4783 0.220107 13.2953 0.471787 14.8898C0.843902 17.2471 2.71443 19.0463 5.07689 19.3848C7.2346 19.694 9.90038 20 12 20C14.0996 20 16.7654 19.694 18.9231 19.3848C21.2856 19.0463 23.1561 17.2471 23.5282 14.8898C23.7799 13.2953 24 11.4783 24 10C24 8.52168 23.7799 6.70467 23.5282 5.11025C23.1561 2.75285 21.2856 0.953732 18.9231 0.615196C16.7654 0.305998 14.0996 0 12 0C9.90038 0 7.2346 0.305998 5.07689 0.615196ZM9.23071 6.24608C8.28573 6.38149 7.53751 7.10114 7.38867 8.0441C7.28799 8.68187 7.19995 9.40867 7.19995 10C7.19995 10.5913 7.28799 11.3181 7.38867 11.9559C7.53751 12.8989 8.28573 13.6185 9.23071 13.7539C10.0938 13.8776 11.1601 14 12 14C12.8398 14 13.9061 13.8776 14.7692 13.7539C15.7142 13.6185 16.4624 12.8989 16.6112 11.9559C16.7119 11.3181 16.8 10.5913 16.8 10C16.8 9.40867 16.7119 8.68187 16.6112 8.0441C16.4624 7.10114 15.7142 6.38149 14.7692 6.24608C13.9061 6.1224 12.8398 6 12 6C11.1601 6 10.0938 6.1224 9.23071 6.24608Z"
                fill="#9EA5D1"
            />
        </svg>
    );
}
