import { TwitterAccountResponse } from "pages/api/users/me/twitter.page";
import { useEffect, useState } from "react";
import useSWRImmutable from "swr/immutable";

interface HookResult {
    isLoading: boolean;
    isConnected?: boolean;
}

type State = "initialized" | "loading" | "finished";

export default function useTwitter(): HookResult {
    const { isLoading: swrIsLoading, data } =
        useSWRImmutable<TwitterAccountResponse>("/api/users/me/twitter", {
            errorRetryInterval: 1000,
        });

    const [state, setState] = useState<State>(
        data ? "finished" : "initialized"
    );

    useEffect(() => {
        setState((state) => {
            if (data) return "finished";

            switch (state) {
                case "initialized":
                    return swrIsLoading ? "loading" : "initialized";
                case "loading":
                    return swrIsLoading ? "loading" : "finished";
                case "finished":
                    return state;
            }
        });
    }, [data, swrIsLoading]);
    return {
        isLoading: state === "loading",
        isConnected: state === "finished" ? !!data : undefined,
    };
}
