import { clsx } from "clsx";
import ProfilePicture, { Size } from "components/ProfilePicture";
import { accountIdentifier } from "lib/ethereum-string";
import { AddressZero } from "lib/zod/types";
import Link from "next/link";
import { HTMLAttributes, PropsWithChildren } from "react";
import { EthereumAccount } from "types";

interface AccountProps {
    account: EthereumAccount | null;
    isLink?: boolean;
    size: Size;
    className?: string;
}

export default function Account({
    account,
    isLink,
    size,
    className: classNameProp,
}: AccountProps): JSX.Element {
    const className = clsx(
        "ml-2 text-md font-medium flex items-center truncate",
        classNameProp
    );

    return (
        <>
            {account && account.address !== AddressZero ? (
                <Container
                    href={`/user/${account.address}`}
                    className="flex items-center"
                    isLink={Boolean(isLink)}
                >
                    <ProfilePicture account={account} size={size} />
                    <div className={clsx("text-gray-900", className)}>
                        {accountIdentifier(account)}
                    </div>
                </Container>
            ) : (
                <div className="flex items-center">
                    <ProfilePicture size={size} />
                    <div className={clsx("text-gray-500", className)}>
                        unknown
                    </div>
                </div>
            )}
        </>
    );
}

function Container({
    isLink,
    href,
    className,
    children,
}: PropsWithChildren<
    HTMLAttributes<HTMLDivElement> & { isLink: boolean; href: string }
>) {
    if (isLink) {
        return (
            <Link href={href} className={className}>
                {children}
            </Link>
        );
    } else {
        return <div className={className}>{children}</div>;
    }
}
