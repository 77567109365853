import useIsMounted from "client/hooks/useIsMounted";
import useUser from "client/hooks/useUser";
import Button from "components/button";
import ConnectWalletButton from "components/ConnectWalletButton";
import HomageIcon from "components/icon/HomageIcon";
import Link from "next/link";
import AccountDropdown from "./AccountDropdown";

const SIDE_CLASSNAME = "w-32 sm:w-40 md:w-64 lg:w-72 xl:w-96 flex items-center";

interface HeaderProps {
    centerContent?: JSX.Element | null;
}

export default function Header({ centerContent }: HeaderProps) {
    return (
        <header className="z-10 relative flex justify-between items-center h-20 md:h-[104px] gap-3">
            <div className={SIDE_CLASSNAME}>
                <Link href="/">
                    <HomageIcon />
                </Link>
            </div>
            {centerContent}
            <div className={`flex justify-end ${SIDE_CLASSNAME}`}>
                <AccountSection />
            </div>
        </header>
    );
}

function AccountSection(): JSX.Element | null {
    const isMounted = useIsMounted();
    const { user } = useUser();

    if (!isMounted) return null;

    if (user) return <AccountDropdown />;

    return (
        <>
            <Link href="/about">
                <Button variant="ghost" size="small" className="mr-2">
                    About
                </Button>
            </Link>
            <ConnectWalletButton size="small" />
        </>
    );
}
