import useUser from "client/hooks/useUser";
import Button, { ButtonProps } from "components/button";
import { ConnectKitButton } from "connectkit";

export default function ConnectWalletButton(
    props: Omit<ButtonProps, "ref">
): JSX.Element | null {
    const { user } = useUser();

    if (user) return null;

    return (
        <ConnectKitButton.Custom>
            {({ show }) => (
                <Button
                    onClick={(event) => {
                        event.preventDefault();
                        show?.();
                    }}
                    {...props}
                >
                    Sign In
                </Button>
            )}
        </ConnectKitButton.Custom>
    );
}
