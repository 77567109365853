import Button from "components/button/Button";
import Icon from "components/icon";
import { createMutator } from "lib/api";
import { GenerateTwitterAuthUrlResponse } from "pages/api/twitter/oauth/generate-auth-url.page";
import { useCallback, useState } from "react";
import useSWRMutation from "swr/mutation";
import { track } from "client/lib/analytics";

export default function ConnectTwitterCTA(): JSX.Element | null {
    const [connectTwitterClicked, setConnectTwitterClicked] = useState(false);

    const { trigger, isMutating } = useSWRMutation(
        `/api/twitter/oauth/generate-auth-url`,
        createMutator<GenerateTwitterAuthUrlResponse>("PUT")
    );

    const authenticateWithTwitter = useCallback(() => {
        const authenticateWithTwitterAsync = async () => {
            const result = await trigger();

            if (!result) {
                return;
            }

            const { authUrl } = result;

            window.open(authUrl, "_blank", "noreferrer");
            setConnectTwitterClicked(true);
        };

        track("click:connect-twitter");

        void authenticateWithTwitterAsync();
    }, [trigger]);

    return (
        <div className="flex flex-col gap-3">
            <div className="text-xs text-gray-600">
                Connect to improve suggestions
            </div>
            <Button
                onClick={authenticateWithTwitter}
                disabled={isMutating || connectTwitterClicked}
                className="bg-twitterblue border-twitterblue"
            >
                <Icon name="twitter" />
                <span className="ml-3">Connect Twitter</span>
            </Button>
        </div>
    );
}
