// Reference: https://github.com/esinx/react-swr-infinite-scroll/blob/main/src/components/InfiniteScroll/index.tsx
import { Ref, useEffect, useState } from "react";

interface InfiniteScrollTriggerProps {
    fetchNextPage: () => void;
    stopFetching: boolean;
    isValidating: boolean;
}

const useIntersection = <T extends HTMLElement>(): [boolean, Ref<T>] => {
    const [intersecting, setIntersecting] = useState<boolean>(false);
    const [element, setElement] = useState<HTMLElement>();

    useEffect(() => {
        if (!element) return;
        const observer = new IntersectionObserver((entries) => {
            setIntersecting(entries[0]?.isIntersecting);
        });
        observer.observe(element);
        return () => observer.unobserve(element);
    }, [element, setIntersecting]);

    return [intersecting, (el) => el && setElement(el)];
};

export default function InfiniteScrollTrigger({
    fetchNextPage,
    stopFetching,
    isValidating,
}: InfiniteScrollTriggerProps): JSX.Element {
    const [intersecting, ref] = useIntersection<HTMLDivElement>();

    useEffect(() => {
        if (!stopFetching && !isValidating && intersecting) {
            fetchNextPage();
        }
    }, [intersecting, fetchNextPage, stopFetching, isValidating]);

    return (
        <div className="relative">
            <div ref={ref} className="absolute" />
        </div>
    );
}
