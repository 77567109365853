import Divider from "components/Divider";
import NftGrid from "components/Gallery/NftGrid";
import Loader from "components/loader";
import Tabs, { TabsContent, TabsList, TabsTrigger } from "components/Tabs";
import { Address } from "lib/zod/types";
import { GalleryMetadataResponse } from "pages/api/users/[address]/nfts/gallery-metadata.page";
import { UserNftsResponse } from "pages/api/users/[address]/nfts/types";
import useSWR from "swr";
import useSWRInfinite, { SWRInfiniteKeyLoader } from "swr/infinite";

interface GalleryProps {
    address: Address;
    indexed: boolean;
}

export default function Gallery({
    address,
    indexed,
}: GalleryProps): JSX.Element {
    const { data: metadata } = useSWR<GalleryMetadataResponse>(
        `/api/users/${address}/nfts/gallery-metadata?indexed=${indexed}`
    );

    const remintedResponse = useSWRInfinite<UserNftsResponse>(
        getKey(address, "reminted", metadata?.numReminted),
        { revalidateFirstPage: false }
    );

    const createdResponse = useSWRInfinite<UserNftsResponse>(
        getKey(address, "created", metadata?.created.count, indexed),
        { revalidateFirstPage: false }
    );

    const ownedResponse = useSWRInfinite<UserNftsResponse>(
        getKey(address, "owned", metadata?.numOwned),
        { revalidateFirstPage: false }
    );

    if (!metadata) {
        return (
            <div className="flex justify-center mt-10">
                <Loader size="medium" />
            </div>
        );
    }

    return (
        <Tabs defaultValue={getDefaultTab(metadata)}>
            <Divider className="mt-10" />
            <TabsList className="flex">
                {!!metadata.numReminted && (
                    <TabsTrigger variant="pill" value="reminted">
                        Reminted ({metadata.numReminted})
                    </TabsTrigger>
                )}
                {!!metadata.created.count && (
                    <TabsTrigger variant="pill" value="created">
                        Created ({metadata.created.count}
                        {metadata.created.hasMore && "+"})
                    </TabsTrigger>
                )}
                {!!metadata.numOwned && (
                    <TabsTrigger variant="pill" value="owned">
                        Owned ({metadata.numOwned})
                    </TabsTrigger>
                )}
            </TabsList>
            <Divider />
            {!!metadata.numReminted && (
                <TabsContent value="reminted">
                    <NftGrid response={remintedResponse} />
                </TabsContent>
            )}
            {!!metadata.created.count && (
                <TabsContent value="created">
                    <NftGrid response={createdResponse} />
                </TabsContent>
            )}
            {!!metadata.numOwned && (
                <TabsContent value="owned">
                    <NftGrid response={ownedResponse} />
                </TabsContent>
            )}
        </Tabs>
    );
}

function getDefaultTab(metadata: GalleryMetadataResponse): string {
    if (metadata.numReminted) return "reminted";
    if (metadata.created.count) return "created";
    return "owned";
}

export function getKey(
    address: Address,
    tab: string,
    count?: number,
    indexed?: boolean
): SWRInfiniteKeyLoader<UserNftsResponse> {
    const baseUrl = `/api/users/${address}/nfts/${tab}`;
    const params = new URLSearchParams();
    if (indexed === false) params.set("indexed", "false");

    return (pageIndex, previousPageData) => {
        if (!count) return null;
        if (pageIndex === 0) return `${baseUrl}?${params}`;
        if (!previousPageData?.cursor) return null;
        params.set("cursor", previousPageData.cursor);
        return `${baseUrl}?${params}`;
    };
}
