import { clsx } from "clsx";
import { PropsWithChildren } from "react";
import Container from "./Container";
import Header from "./Header";

export interface LayoutProps {
    showHeader?: boolean;
    className?: string;
}

export default function Layout({
    showHeader = true,
    className,
    children,
}: PropsWithChildren<LayoutProps>): JSX.Element {
    return (
        <>
            {showHeader && (
                <Container>
                    <Header />
                </Container>
            )}
            <main className={clsx("overscroll-none flex-grow", className)}>
                {children}
            </main>
        </>
    );
}
