import {
    addressValidator,
    transactionHashValidator,
} from "lib/zod/validators.mjs";
import { z } from "zod";

export type Address = z.infer<typeof addressValidator>;

export const AddressZero = addressValidator.parse(
    "0x0000000000000000000000000000000000000000"
);

export type TransactionHash = z.infer<typeof transactionHashValidator>;
