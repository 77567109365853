import * as TabsPrimitive from "@radix-ui/react-tabs";
import { clsx } from "clsx";

interface TabsTriggerProps
    extends Pick<TabsPrimitive.TabsTriggerProps, "value" | "children"> {
    variant: "pill" | "ghost";
}

export function TabsTrigger({
    variant,
    value,
    children,
}: TabsTriggerProps): JSX.Element {
    return (
        <TabsPrimitive.Trigger
            value={value}
            className={clsx({
                "flex items-center px-6 py-2 rounded-full text-sm font-medium cursor-pointer data-[state=inactive]:text-gray-400 data-[state=active]:bg-gray-200":
                    variant === "ghost",
                "flex flex-row gap-1 my-5 mr-4 py-1.5 px-5 cursor-pointer rounded-full data-[state=inactive]:hover:bg-gray-100 data-[state=active]:bg-gray-200":
                    variant === "pill",
            })}
        >
            {children}
        </TabsPrimitive.Trigger>
    );
}

export default TabsPrimitive.Root;
export const TabsList = TabsPrimitive.List;
export const TabsContent = TabsPrimitive.Content;
