import { clsx } from "clsx";
import { PropsWithChildren } from "react";

interface ContainerProps {
    className?: string;
}

export default function Container({
    children,
    className,
}: PropsWithChildren<ContainerProps>): JSX.Element {
    return (
        <div
            className={clsx(
                "w-full max-w-[1040px] mx-auto overscroll-none px-3 md:px-8",
                className
            )}
        >
            {children}
        </div>
    );
}
