import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import * as Portal from "@radix-ui/react-portal";
import useTwitter from "client/hooks/useTwitter";
import useUser from "client/hooks/useUser";
import Button from "components/button/Button";
import Divider from "components/Divider";
import ProfilePicture from "components/ProfilePicture";
import useSearch from "context/search";
import Link from "next/link";
import ConnectTwitterCTA from "pages/home/ConnectTwitterCTA";
import { useCallback } from "react";
import Icon from "components/icon";

const BUTTON_CLASS = "h-12 w-full !justify-start mb-1 !rounded-xs";
const ICON_CLASS = "h-5 w-5";

export default function AccountDropdown(): JSX.Element {
    const { user, signOutAndDisconnect } = useUser();
    if (!user) throw new Error();

    const { isLoading, isConnected } = useTwitter();

    const { openSearch } = useSearch();
    const open = useCallback(() => openSearch("AccountDropdown"), [openSearch]);

    return (
        <DropdownMenu.Root modal={false}>
            <DropdownMenu.Trigger asChild>
                <button className="relative">
                    {!isConnected && (
                        <div className="absolute bg-twitterblue h-1.5 w-1.5 rounded-full right-0 top-0" />
                    )}
                    <ProfilePicture account={user} size="small" />
                </button>
            </DropdownMenu.Trigger>
            <Portal.Root>
                <DropdownMenu.Content
                    align="end"
                    sideOffset={15}
                    className="w-96 md:w-52 p-2 border border-gray-50 rounded-lg shadow-xs bg-white flex flex-col z-20"
                >
                    {!isLoading && !isConnected && (
                        <>
                            <div className=" flex flex-col items-center text-center">
                                <ConnectTwitterCTA />
                            </div>
                            <Divider className="w-full my-5" />
                        </>
                    )}
                    <Link href="/gallery">
                        <Button
                            variant="ghost"
                            size="small"
                            className={BUTTON_CLASS}
                        >
                            <Icon name="box" className={ICON_CLASS} />
                            <span className="ml-3">Gallery</span>
                        </Button>
                    </Link>
                    <Button
                        onClick={open}
                        variant="ghost"
                        size="small"
                        className={BUTTON_CLASS}
                    >
                        <Icon name="magnifying-glass" className={ICON_CLASS} />
                        <span className="ml-3">Search</span>
                    </Button>
                    <Link href="/about">
                        <Button
                            variant="ghost"
                            size="small"
                            className={BUTTON_CLASS}
                        >
                            <Icon
                                name="question-mark-circled"
                                className={ICON_CLASS}
                            />
                            <span className="ml-3">About</span>
                        </Button>
                    </Link>
                    <Button
                        variant="ghost"
                        size="small"
                        className={BUTTON_CLASS}
                        onClick={signOutAndDisconnect}
                    >
                        <Icon name="exit" className={ICON_CLASS} />
                        <span className="ml-3">Sign out</span>
                    </Button>
                </DropdownMenu.Content>
            </Portal.Root>
        </DropdownMenu.Root>
    );
}
