import Avatar from "boring-avatars";
import useIsMounted from "client/hooks/useIsMounted";
import { clsx } from "clsx";
import { Address } from "lib/zod/types";
import { useState } from "react";
import { ChainId } from "server/services/ethereum/constants";
import { EthereumAccount } from "types";
import { useEnsAvatar } from "wagmi";

export type Size = "extrasmall" | "small" | "medium" | "large";

function sizeToPx(size: Size): number {
    switch (size) {
        case "extrasmall":
            return 20;
        case "small":
            return 32;
        case "medium":
            return 56;
        case "large":
            return 128;
    }
}

function sizeToClassName(size: Size): string {
    switch (size) {
        case "extrasmall":
            return "h-5 w-5";
        case "small":
            return "h-8 w-8";
        case "medium":
            return "h-14 w-14";
        case "large":
            return "h-32 w-32";
    }
}

interface ProfilePictureProps {
    account?: EthereumAccount;
    size: Size;
}

export default function ProfilePicture({
    account,
    size,
}: ProfilePictureProps): JSX.Element {
    if (!account) {
        return (
            <div
                className={clsx(
                    "rounded-full bg-gray-500",
                    sizeToClassName(size)
                )}
            />
        );
    }

    return account.ensName ? (
        <ProfilePictureWithEnsName account={account} size={size} />
    ) : (
        <ProfilePictureWithoutAvatar address={account.address} size={size} />
    );
}

interface ProfilePictureWithEnsNameProps {
    account: EthereumAccount;
    size: Size;
}

function ProfilePictureWithEnsName({
    account,
    size,
}: ProfilePictureWithEnsNameProps): JSX.Element {
    const isMounted = useIsMounted();
    const { data: avatar } = useEnsAvatar({
        name: account.ensName,
        chainId: ChainId.Mainnet,
    });
    const [loaded, setLoaded] = useState(false);

    if (!isMounted || !avatar)
        return (
            <ProfilePictureWithoutAvatar
                address={account.address}
                size={size}
            />
        );

    return (
        <>
            {!loaded && (
                <ProfilePictureWithoutAvatar
                    address={account.address}
                    size={size}
                />
            )}
            <img // eslint-disable-line @next/next/no-img-element
                src={avatar}
                alt={`${account.address} avatar`}
                className={clsx(
                    "rounded-full",
                    sizeToClassName(size),
                    !loaded && "hidden"
                )}
                onLoad={() => setLoaded(true)}
            />
        </>
    );
}

interface ProfilePictureWithoutAvatarProps {
    address: Address;
    size: Size;
}

function ProfilePictureWithoutAvatar({
    address,
    size,
}: ProfilePictureWithoutAvatarProps): JSX.Element {
    return <Avatar size={sizeToPx(size)} name={address} />;
}
